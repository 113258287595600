<template>
  <v-card>
    <v-app-bar app dark color="#0F6D55">
      <v-toolbar-title>โปรแกรมพิมพ์บัตร </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
  </v-card>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style></style>
