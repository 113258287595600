<template>
  <v-main>
    <!-- snackbar --------------------------------------------------->
    <v-snackbar
      right
      top
      v-model="snackbar.showing"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      <v-icon class="pr-4">{{ snackbar.icon }}</v-icon>
      {{ snackbar.text }}
    </v-snackbar>
    <!-- snackbar --------------------------------------------------->
    <!-- dialog --------------------------------------------------->
    <v-dialog v-model="dialog.showing" :max-width="dialog.width">
      <v-card>
        <v-toolbar :color="dialog.titlecolor" dark
          >{{ dialog.title }}
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog.showing = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="body">
          <br />
          {{ dialog.text }}
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- dialog --------------------------------------------------->

    <router-view></router-view>
  </v-main>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Content",
  computed: {
    ...mapState(["snackbar"]),
    ...mapState(["dialog"]),
  },
};
</script>

<style></style>
