import jsPDF from "jspdf";
// import * as fontTh from "@/assets/font/THSarabunNew-normal";

export function printcardPDF(filedata, type) {
  var pdf = new jsPDF("p", "mm", "a4");

  pdf.setProperties({
    title: type,
  });

  let i = 0;
  for (let index = 1; index <= filedata.length; index++) {
    switch (index) {
      case 1:
        pdf.addImage(
          filedata[i].imgDataUrl,
          "PNG",
          0,
          3,
          105,
          90,
          "alias1",
          "SLOW"
        );
        break;
      case 2:
        pdf.addImage(
          filedata[i].imgDataUrl,
          "PNG",
          105,
          3,
          105,
          90,
          "alias2",
          "SLOW"
        );
        break;
      case 3:
        pdf.addImage(
          filedata[i].imgDataUrl,
          "PNG",
          0,
          100,
          105,
          90,
          "alias3",
          "SLOW"
        );
        break;
      case 4:
        pdf.addImage(
          filedata[i].imgDataUrl,
          "PNG",
          105,
          100,
          105,
          90,
          "alias4",
          "SLOW"
        );
        break;
      case 5:
        pdf.addImage(
          filedata[i].imgDataUrl,
          "PNG",
          0,
          200,
          105,
          90,
          "alias5",
          "SLOW"
        );
        break;
      case 6:
        pdf.addImage(
          filedata[i].imgDataUrl,
          "PNG",
          105,
          200,
          105,
          90,
          "alias6",
          "SLOW"
        );
        break;
    }
    i++;
  }

  return pdf.output("datauristring");
}
