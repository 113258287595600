<template>
  <v-dialog persistent v-model="visible" max-width="800">
    <v-card>
      <v-btn
        @click="onCancel()"
        fab
        x-small
        absolute
        top
        right
        class="yellowColor"
      >
        <v-icon> mdi-close </v-icon>
      </v-btn>
      <v-toolbar :color="colorHeader" dark>{{ title }}</v-toolbar>

      <iframe :src="pdfViewer" width="800" height="500"></iframe>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Pdfviewer",
  props: {
    title: String,
    pdfViewer: String,
    colorHeader: String,
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onCancel() {
      this.$emit("cancelled");
    },
  },
};
</script>

<style scoped>
>>> .v-dialog {
  overflow-y: visible;
}
</style>
