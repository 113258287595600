import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue2Filters from "vue2-filters";
import { VueMaskDirective } from "v-mask";
import vuetify from "./plugins/vuetify";
import moment from "moment";
import VueKonva from "vue-konva";

Vue.use(VueKonva);

Vue.directive("mask", VueMaskDirective);
moment.locale("th");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

Vue.use(Vue2Filters);
